/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import * as L from 'leaflet';
import { Coordinates } from '../../../../../../library/interfaces/location';

export interface LiveBlip {
  name: string;
  coordinates: Coordinates;
  blipSprite: number;
  type: string;
  id: `${string}-${string}-${string}-${string}-${string}` | number;
}

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [],
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss'
})
export class MapComponent implements OnChanges, AfterViewInit {
  @Input() coordinates: any = { x: 0, y: 0, z: 0, default: true };
  @Input() zoomEnabled: boolean = true;
  @Input() panToLocation: boolean = false;
  @Input() blip: number = 1;
  @Input() blipList: any[] = [];
  @Output() clickedMarker: EventEmitter<any> = new EventEmitter<any>();

  public ExampleGroup = L.layerGroup();
  public Icons = {
    "Example": this.ExampleGroup,
  };

  private map: any;
  private marker: any;
  private markerList: any[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    // @ts-ignore
    if (changes.coordinates) {
      // @ts-ignore
      this.coordinates = changes.coordinates.currentValue;
      if (this.marker && this.map && !this.coordinates.hasOwnProperty('default')) {
        // Remove existing markers
        this.map.eachLayer((layer: any) => {
          if (layer instanceof L.Marker) {
            layer.remove();
          }
        });

        this.marker.setLatLng(this.convertCoords(this.coordinates));
        this.marker = L.marker(this.convertCoords(this.coordinates), this.getIcon(this.blip)).addTo(this.map);
        this.map.setView(this.convertCoords(this.coordinates), 4, { animate: true, duration: 1 });
      }
    }

    // @ts-ignore
    if (changes.blipList) {
      if (this.map) {
        // @ts-ignore
        this.blipList = changes.blipList.currentValue;

        for (let marker of this.markerList) {
          this.map.removeLayer(marker);
        }

        for (let blip of this.blipList) {
          const marker = L.marker(this.convertCoords(blip.coordinates), { ...this.getIcon(blip.blipSprite), title: blip.name }).addTo(this.map);
          
          if(blip.type === 'vehicle'){
            marker.bindTooltip(`${blip.name}`, { permanent: false, direction: 'auto' });
          }

          if(blip.type === 'player'){
            marker.bindTooltip(`[${blip.id}] ${blip.name}`, { permanent: false, direction: 'auto' });
          }
          marker.on('click', (e: L.LeafletMouseEvent) => this.markerClicked(blip.id));
          this.markerList.push(marker);
        }

        this.map.setView(this.convertCoords({ x: 500, y: -1200, z: 0 }), 2, { animate: true, duration: 1 });
      }
    }
  }

  private CUSTOM_CRS = L.extend({}, L.CRS.Simple, {
    projection: L.Projection.LonLat,
    scale: function (zoom: any) {
      return Math.pow(2, zoom);
    },
    zoom: function (sc: any) {
      return Math.log(sc) / 0.6931471805599453;
    },
    distance: function (pos1: any, pos2: any) {
      var x_difference = pos2.lng - pos1.lng;
      var y_difference = pos2.lat - pos1.lat;
      return Math.sqrt(x_difference * x_difference + y_difference * y_difference);
    },
    transformation: new L.Transformation(0.02072, 117.3, -0.0205, 172.8),
    infinite: true
  });

  private initMap(): void {
    let center = [-400, -1200];
    let defaultZoom = 1;
    if (this.panToLocation) {
      center = this.convertCoords(this.coordinates);
      defaultZoom = 4;
    }
    this.map = L.map('map', {
      crs: this.CUSTOM_CRS,
      minZoom: 1,
      maxZoom: 4,
      preferCanvas: true,
      // @ts-ignore
      center: center,
      zoom: defaultZoom,
      attributionControl: false,
      dragging: this.zoomEnabled,
      scrollWheelZoom: this.zoomEnabled,
      zoomControl: this.zoomEnabled
    });

    const tiles = L.tileLayer('../../../assets/map/atlas/{z}/{x}/{y}.jpg', {
      minZoom: 0,
      maxZoom: 5,
      noWrap: true,
      attribution: 'Online map GTA V',
      id: 'styleAtlas map',
    });

    tiles.addTo(this.map);

    if (this.blipList.length === 0) {
      if (!this.coordinates.hasOwnProperty('default')) {
        this.marker = L.marker(this.convertCoords(this.coordinates), this.getIcon(this.blip)).addTo(this.map);
        this.map.setView(this.convertCoords(this.coordinates), 4, { animate: true, duration: 1 });
      }
    } else {
      for (let blip of this.blipList) {
        const marker = L.marker(this.convertCoords(blip.coordinates), { ...this.getIcon(blip.blipSprite), title: blip.name }).addTo(this.map);
        marker.on('click', (e: L.LeafletMouseEvent) => this.markerClicked(blip.id));
        this.markerList.push(marker);
      }
    }
  }

  constructor() { }

  ngAfterViewInit(): void {
    this.initMap();
  }

  private markerClicked(blipName: string): void {
    this.clickedMarker.emit(blipName);
  }

  private convertCoords(gameCoordinates: { x: number, y: number, z: number, h?: number }): [number, number] {
    console.log(gameCoordinates);
    return [gameCoordinates.y, gameCoordinates.x];
  }

  private getIcon(icon: number) {
    return {
      icon: L.icon({
        iconUrl: `../../../assets/map/blips/${icon}.png`,
        iconSize: [30, 30],
        iconAnchor: [20, 20],
        popupAnchor: [-10, -27]
      })
    }
  }
}
