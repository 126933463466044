/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
**************************************************/
import { Component } from '@angular/core';
import { Player } from '../../../../../../../library/interfaces/player';
import { SocketUpdateMessage } from '../../../../../../../library/interfaces/socket';
import { ApiService } from '../../../../public/services/api.service';
import { WebsocketService } from '../../../../public/services/websocket.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DataService } from '../../../../public/services/data.service';
import { LiveBlip, MapComponent } from '../../../../public/components/map/map.component';
import { ActivityNameMapPipe } from '../../../../pipes/activity-name-map.pipe';

@Component({
  selector: 'app-active',
  standalone: true,
  imports: [ RouterLink, CommonModule, MapComponent, ActivityNameMapPipe ],
  templateUrl: './active.component.html',
  styleUrl: './active.component.scss'
})
export class ActiveComponent {
  public pendingKicks: any[] = [];
  public playerList: Player[] = [];
  public fetchingData = true;
  public blipList: LiveBlip[] = [];
  private cachedPlayerList: Player[] = [];
  private cacheTime = 0;

  constructor(
    private websocketService: WebsocketService,
    private clipboard: Clipboard,
    private apiService: ApiService,
    private dataService: DataService,
    private router: Router
  ) {
    this.websocketService.getMessage().subscribe((update: SocketUpdateMessage) => {
        switch(update.type){
          case 'players':
              if(JSON.stringify(this.cachedPlayerList) !== JSON.stringify(update.data) && Math.abs(Date.now() - this.cacheTime) > 1000){
                this.setPlayerList(update.data);
                this.cachedPlayerList = update.data;
                this.cacheTime = Date.now();
              }
            break;
        }
    });
  }

  public async kickPlayer(userId: number | undefined){
    if(!this.pendingKicks.includes(userId)){
      this.pendingKicks.push(userId);
      const removeVehicleCall = await this.apiService.call('/players/remove/by-id/' + userId);
      if(removeVehicleCall.success && removeVehicleCall.data){
        this.pendingKicks = this.pendingKicks.filter((uuidFromList) => uuidFromList !== userId);
        this.playerList = this.playerList.filter((player) => player.userData.ids.userId !== userId);
      } else {
        this.pendingKicks = this.pendingKicks.filter((uuidFromList) => uuidFromList !== userId);
      }
    }
  }

  public handleClickedMarker(userId: any){
    if(this.playerList.find((player) => player.userData.ids.userId === userId)){
      this.router.navigate(['/admin/players/inspect/' + userId]);
    }
  }

  private setPlayerList(playerList: Player[]){
    this.playerList = playerList;
    
    const newBlipList: LiveBlip[] = [];
    for(let player of this.playerList){
      newBlipList.push({
        name: `${player.character?.firstName} ${player.character?.lastName}`,
        coordinates: player.character?.location.coords as any,
        blipSprite: 280,
        type: 'player',
        id: player.userData.ids.userId as number
      });
    }

    this.blipList = newBlipList;
  }

  async ngOnInit(): Promise<void> {
    this.setPlayerList(await this.dataService.getActivePlayers());
    this.fetchingData = false;
  }

  public copyValue(value: any){
    this.clipboard.copy(value);
  }
}
