/************************************************
 *   :---:.                     .::::..          *
 *   :@@@@@%-               :+#@@@@@@@@@%*-      *
 *    :@@@@@@=            =@@@@@@@@@@@@@@@@@.    *
 *     -@@@@@@-          #@@@@@@@%***#@@@@=      *
 *      =@@@@@@.        #@@@@@%-       :=        *
 *       =@@@@@%.      #@@@@@#                   *
 *        +@@@@@%.    #@@@@@*                    *
 *         +@@@@@@.  %@@@@@*.                    *
 *           @@@@@%:%@@@@@*.%%.                  *
 *            @@@@@@@@@@@# %@@@+:     .=+.       *
 *            %@@@@@@@@@% #@@@@@@@%#%@@@@@*.     *
 *            .@@@@@@@@@. :*@@@@@@@@@@@@@@@#.    *
 *             :@@@@@@@:     :+#@@@@@@@%*-       *
 *              ......                           *
 *   ┌────────────────────────────────────────┐  *
 *   │         Vereenigde Compagnie           │  *
 *   │             fiveM server               │  *
 *   ├────────────────────────────────────────┤  *
 *   │                vcrp.nl                 │  *
 *   └────────────────────────────────────────┘  *
*************************************************/
import { Component, OnInit } from '@angular/core';
import { WebsocketService } from '../../../public/services/websocket.service';
import { ApiService } from '../../../public/services/api.service';
import { Router } from '@angular/router';
import { SocketUpdateMessage } from '../../../../../../library/interfaces/socket';
import { LogMessage } from '../../../../../../library/interfaces/log';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-logs',
  standalone: true,
  imports: [ CommonModule ],
  templateUrl: './logs.component.html',
  styleUrl: './logs.component.scss'
})
export class LogsComponent implements OnInit {
  public logs: any[] = [];
  public sysLogs: any[] = [];

  constructor(
    private websocketService: WebsocketService,
    private apiService: ApiService
  ) {
    this.websocketService.getMessage().subscribe((update: SocketUpdateMessage) => {
        switch(update.type){
          case 'log':
            const logMessage: LogMessage = update.data;
            this.logs.push(logMessage);
            break;
          case 'syslog':
            const object = {...update.data, date: Date.now()};
            this.sysLogs.push(object);
            break;
        }
    });
  }

  async ngOnInit(): Promise<void> {
    const logs = await this.apiService.call('/logs/server');
    if(logs.success) this.logs = this.sortLogs(logs.data);
  }

  private sortLogs(logs: LogMessage[]): LogMessage[] {
    return logs.sort((a: LogMessage, b: LogMessage) => {
      let timeA = new Date(a.time as string);
      let timeB = new Date(b.time as string);
      // @ts-ignore
      return timeA - timeB;
    });
  }

  public objectKeys(obj: any): string[] {
    if(obj) return Object.keys(obj);
    return [];
  }
}
