<div class="logs-wrapper">
    <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active d-flex align-items-center" id="simple-tab-0" data-bs-toggle="tab" href="#simple-tabpanel-0" role="tab" aria-controls="simple-tabpanel-0" aria-selected="true">
                <i class="me-2 text-end fa-fw fa-light fa-square-terminal"></i>Live Syslog
            </a>
        </li>
        <li class="nav-item d-flex align-items-center" role="presentation">
            <a class="nav-link" id="simple-tab-1" data-bs-toggle="tab" href="#simple-tabpanel-1" role="tab" aria-controls="simple-tabpanel-1" aria-selected="false">
                <i class="me-2 text-end fa-fw fa-light fa-calendar-lines"></i>Event Log
            </a>
        </li>
    </ul>
    <div class="tab-content" id="tab-content">
        <div class="tab-pane active" id="simple-tabpanel-0" role="tabpanel" aria-labelledby="simple-tab-0">
            <div class="card logs-container">
                <div class="accordion accordion-flush d-flex flex-column-reverse" id="accordionFlushExample">
                    <div class="log-line accordion-item severity {{log.logLevel}}" *ngFor="let log of sysLogs">
                        <div class="d-flex">
                            <div class="collapser"><i class="fa-solid fa-angle-down"></i></div>
                            <a class="accordion-button collapsed ms-1" type="button" data-bs-toggle="collapse" href="#log-{{log.date}}" aria-expanded="false">
                                <div class="timestamp me-2">{{ log.date | date: 'dd-MM-yyyy HH:mm:ss' }}</div>
                                <div class="origin {{log.logLevel}} me-1" *ngIf="log.origin">[{{log.origin}}]</div>
                                <div class="message">{{log.logMessage}}</div>
                            </a>
                        </div>
                        <div id="log-{{log.date}}" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample" *ngIf="objectKeys(log.extraArgs).length > 0">
                            <div class="accordion-body ms-2">
                                <table>
                                    <tr *ngFor="let key of objectKeys(log.extraArgs)">
                                        <td class="pe-2">{{ key }}:</td>
                                        <td>{{ log.extraArgs[key] }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane" id="simple-tabpanel-1" role="tabpanel" aria-labelledby="simple-tab-1">
            <div class="card logs-container">
                <div class="accordion accordion-flush d-flex flex-column-reverse" id="accordionFlushExample">
                    <div class="log-line accordion-item severity {{log.level}} {{log.logLevel}}" *ngFor="let log of logs">
                        <div class="d-flex">
                            <div class="collapser"><i class="fa-solid fa-angle-down"></i></div>
                            <a class="accordion-button collapsed ms-1" type="button" data-bs-toggle="collapse" href="#log-{{log.time}}-{{log.uuid}}" aria-expanded="false">
                                <div class="timestamp me-2">{{ log.time | date: 'dd-MM-yyyy HH:mm:ss' }}</div>
                                <div class="message">{{log.message}}</div>
                            </a>
                        </div>
                        <div id="log-{{log.time}}-{{log.uuid}}" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div class="accordion-body ms-2">
                                <table>
                                    <tr>
                                        <td class="pe-2">UserID:</td>
                                        <td>{{ log.userId }}</td>
                                    </tr>
                                    <tr>
                                        <td class="pe-2">Cat:</td>
                                        <td>{{ log.category }}</td>
                                    </tr>
                                    <tr>
                                        <td class="pe-2">UUID:</td>
                                        <td>{{ log.uuid }}</td>
                                    </tr>
                                    <ng-container *ngIf="log.extraArgs">
                                        <tr *ngFor="let key of objectKeys(log.extraArgs)">
                                            <td class="pe-2">{{ key }}:</td>
                                            <td>{{ log.extraArgs[key] }}</td>
                                        </tr>
                                    </ng-container>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>